video = document.createElement("video");
if (
  video.canPlayType('video/webm; codecs="vp8, vorbis"') === "" ||
  screen.orientation.type.startsWith("portrait") ||
  isMobile()
) {
  document.getElementById("image-logo").style.display = "block";
  document.getElementById("video-logo").style.display = "none";
}

function isMobile() {
  const regex =
    /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return regex.test(navigator.userAgent);
}

var popupSize = {
  width: 780,
  height: 550,
};

$(document).on("click", ".social-button", function (e) {
  var verticalPos = Math.floor(($(window).width() - popupSize.width) / 2),
    horisontalPos = Math.floor(($(window).height() - popupSize.height) / 2);

  var popup = window.open(
    $(this).prop("href"),
    "social",
    "width=" +
      popupSize.width +
      ",height=" +
      popupSize.height +
      ",left=" +
      verticalPos +
      ",top=" +
      horisontalPos +
      ",location=0,menubar=0,toolbar=0,status=0,scrollbars=1,resizable=1",
  );

  if (popup) {
    popup.focus();
    e.preventDefault();
  }
});

